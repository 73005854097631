<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="70vw"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        fab
        dark
        small
        v-bind="attrs"
        :color="statusColor[`${visitStore.visitData.status[1].value}`]"
        v-on="on"
      >
        <v-icon color="white">
          {{ icons.mdiLanPending }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="tw-break-words"><strong>Your</strong> prescription prepared <strong>Your</strong> way</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="2xl:tw-container 2xl:tw-mx-auto lg:tw-px-20 md:tw-py-12 md:tw-px-6 tw-py-9 tw-px-4">
            <div class="tw-text-center">
              <h2 class="lg:tw-text-4xl dark:tw-text-white tw-text-3xl lg:tw-leading-9 tw-leading-7 tw-font-semibold tw-text-gray-800">
                Our Prescription Fulfillment Process
              </h2>
              <p class="tw-font-normal dark:tw-text-gray-400 tw-text-base lg:tw-leading-4 tw-leading-6 tw-text-gray-600 lg:tw-mt-4 md:tw-mt-2 tw-mt-4">
                Your health and well being are our priority. Our prescription fulfillment process is designed to ensure that your prescription is delivered to you in a timely manner.
              </p>
            </div>

            <div class="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-grid-cols-1 lg:tw-gap-8 tw-gap-10 lg:tw-mt-16 tw-mt-10">
              <!-- Order Online Grid Card -->
              <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
                <img
                  class="dark:tw-hidden tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/online%20shop_online%20support-customer%20service-call%20center-operator-customer%20support.png"
                  alt="Order Online"
                >
                <img
                  class="tw-hidden dark:tw-block tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/online%20shop_online%20support-customer%20service-call%20center-operator-customer%20support.png"
                  alt="Order Online"
                >
                <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
                  Online Portal
                </p>
                <p class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4">
                  Get live updates on your prescription status
                </p>
              </div>

              <!-- Personalization Grid Card -->
              <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
                <img
                  class="dark:tw-hidden tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/hospital-building-medical-health%20clinic-urban.png"
                  alt="Personalisation"
                >
                <img
                  class="tw-hidden dark:tw-block tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/hospital-building-medical-health%20clinic-urban.png"
                  alt="Personalisation"
                >
                <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
                  Circle of Care
                </p>
                <p class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4 lg:tw-pr-0 tw-pr-10">
                  Drug monitoring and personalization
                </p>
              </div>

              <!-- Shopping Grid Card -->
              <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
                <img
                  class="dark:tw-hidden tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/Icon%20Color_5.png"
                  alt="Delivery"
                >
                <img
                  class="tw-hidden dark:tw-block tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/Icon%20Color_5.png"
                  alt="Delivery"
                >
                <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
                  Delivery
                </p>
                <p class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4">
                  Free Delivery across Canada
                </p>
              </div>

              <!-- Delivery Grid Card -->
              <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
                <img
                  class="dark:tw-hidden tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/16%20Ointment.png"
                  alt="Delivery"
                >
                <img
                  class="tw-hidden dark:tw-block tw-h-14"
                  src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/16%20Ointment.png"
                  alt="Samples"
                >
                <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-red-800 tw-font-semibold tw-leading-5 tw-mt-6">
                  Free Samples
                </p>
                <p class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-red-600 tw-mt-4 lg:tw-pr-0 tw-pr-10">
                  Free Samples as per your prescription with every order
                </p>
              </div>
            </div>
          </div>
          <v-row>
            <v-col
              cols="12"
              sm="8"
              offset-sm="3"
            >
              <v-radio-group v-model="radios">
                <template v-slot:label>
                  <div><strong>Please select how you would like your prescriptions filled</strong></div>
                </template>
                <v-radio value="pharmaSmart">
                  <template v-slot:label>
                    <div><strong class="primary--text">PharmaSmart Pharmacy</strong></div>
                  </template>
                </v-radio>
                <v-radio value="other">
                  <template v-slot:label>
                    <div><strong class="text">Other Pharmacy</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="onSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiLanPending } from '@mdi/js'
import { useVisitStore } from '@/stores/Visit.js'
import { statusColor, statusLabel } from '@/assets/lists'
import { visitService } from '@/api/index.js'

export default {
    data() {
        return {
            radios: 'pharmaSmart',
        }
    },
    setup() {
        const visitStore = useVisitStore()

        const isDialogVisible = ref(false)

        return {
            isDialogVisible,
            visitStore,
            statusColor,
            statusLabel,
            icons: {
                mdiLanPending,
            },
        }
    },
    methods: {
        onSave() {
            if (this.visitStore.visitData.status[1].value !== 'complete') {
                if (this.radios === 'pharmaSmart') {
                    const data = {
                        processing: 'inprogress',

                    // basic: 'complete',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
                if (this.radios === 'other') {
                    const data = {
                        processing: 'transfer',

                    // basic: 'complete',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
                this.isDialogVisible = false
            }
        },
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                processing: data.processing || 'pending',

                // basic: data.basic || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
    },
}
</script>
